'use client'

import { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PlayCircleIcon } from '@heroicons/react/24/outline';
import VideoJS from './video.jsx';
import logoAnimated from "../assets/img/logo-single-animated.svg";

export default function ModalVideoLink(props:any) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [videoLink, setVideoLink] = useState<string>("");
    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef(null);
    const uuid = ( props.uuid !== undefined ? props.uuid : "" );
    const api_url = ( props.api_url !== undefined ? props.api_url : "" );
    const screenerType = ( props.screenerType !== undefined ? props.screenerType : "" );

    let videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        disablePictureInPicture: true,
        sources: [
            {
                src: videoLink,
                type: "application/x-mpegURL"
            }
        ],
        html5: {
            hls: {
                // withCredentials: true,
                overrideNative: true,
            },
        },
        plugins: {
            qualityLevels: {},
        }
    };

    const handlePlayerReady = (player:any) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    const onClick = () => {
        setModalOpen(true);
    }

    const getDigimLink = async() => {         
        const requestOptions:any = {
            //mode: 'no-cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                request: "find",
                layout: "screener",
                screenerUUID: uuid
            })
        };
        const response = await fetch(api_url, requestOptions);

        //console.log(requestOptions);
        //console.log(response);

        const data = await response.json();
        if( data.status === 200 ) {
            setVideoLink(data.link);
            document.getElementById("loading")?.classList.add("hidden");
            document.getElementById("video-holder")?.classList.remove("hidden");
        }
    }

    return (
        <>
        {
            ( props.children !== undefined )
            ?
                <div className="relative cursor-pointer" data-screener={ uuid } onClick={ onClick } aria-label={"Watch " + screenerType }>
                    { props.children }
                    <div className="absolute inset-0 bg-black opacity-40"></div>
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-white hover:text-primary">
                        <PlayCircleIcon className="-ml-0.5 h-16 w-16" aria-hidden="true" />
                        <span className="font-bold">Watch { screenerType }</span>
                    </div>
                </div>
            :
                <button type="button" className="inline-flex items-center justify-center text-xl font-bold text-white hover:text-primary me-4" onClick={() => { setModalOpen(true) }} aria-label={"Watch " + screenerType }>
                    <PlayCircleIcon className="h-8 w-8 me-1" aria-hidden="true" />
                    Play { screenerType }
                </button>
        }
        <Transition show={ modalOpen } as={ Fragment } afterEnter={ getDigimLink }>
            <Dialog initialFocus={ videoRef } onClose={() => setModalOpen(false)}>
                {/* Modal backdrop */}
                <Transition.Child
                    className="fixed inset-0 z-[99999] bg-black bg-opacity-50 transition-opacity"
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-out duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    aria-hidden="true"
                />
                {/* End: Modal backdrop */}

                {/* Modal dialog */}
                <Transition.Child
                    className="fixed inset-0 z-[99999] flex px-4 md:px-6 py-6"
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 scale-75"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-out duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-75"
                >
                <div className="max-w-full md:max-w-5xl mx-auto h-full flex items-center">
                    <button type="button" className="absolute top-10 right-10 z-50 text-white bg-primary/30 hover:bg-primary/50 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="gallery-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <Dialog.Panel className="w-full max-h-full min-h-[200px] aspect-video overflow-hidden">
                        <div id="loading" className="text-center p-6 mb-6" style={{width:200, height: 350}}>
                            <img
                                src={ logoAnimated.src }
                                className="object-contain w-full h-auto"
                            />
                        </div>
                        <div id="video-holder" className="hidden max-w-full" style={{width:900, height: 500}}>
                            { ( videoLink !== "" ) ? <VideoJS options={ videoJsOptions } onReady={ handlePlayerReady } /> : <></> }
                        </div>
                    </Dialog.Panel>
                </div>
                </Transition.Child>
                {/* End: Modal dialog */}
            </Dialog>
        </Transition>
        </>
    )
}